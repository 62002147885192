.table-actions {
  position: relative;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
}

.straddle-button {
  position: absolute;
  bottom: 5%;
  width: 100%;
  margin-left: 2rem;
  background-color: '#37393b';
}
