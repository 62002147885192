@mixin zoom($scale: 1.25) {
  transition: transform 0.2s;
  &:hover {
    z-index: 1;
    transform: scale($scale);
  }
}

@mixin noselect($cursor: default) {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  cursor: $cursor;
}

@mixin disabled($opacity: 0.4) {
  &.disabled {
    pointer-events: none;
    opacity: $opacity;
  }
}
