@import '../../assets/scss/mixins';

.wrapper {
  background: $background-radial;
  min-height: 100vh;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;

  @include primeFont();
}
