$hijack-orange: #e98c26;
$hijack-orange-dark: #cc6b47;
$hijack-black: #090f13;
$background-radial: radial-gradient(135.01% 135.01% at 50% 50%, #012425 3.65%, #000000 100%);
$white: #ffffff;
$gray-button-color: #5a6771;
$gray-triangle-pointer: #c4c4c4;
$dark-gray: #3c3c3c;
$table-header-black: #000000;
$table-body-gray: #36393c;
$table-border-gray: #484848;
$poker-table-preview-container: #161b1f;
$poker-table-preview-color: #383838;
$poker-table-preview-taken-seat: #838383;
$poker-table-preview-empty-seat: #464646;
$poker-table-preview-details: #9e9e9e;
$midnight-blue: #161b1f;
