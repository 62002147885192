.gamecomms_menu {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  background-color: black;
  margin-left: 1rem;
  border-radius: 0.25rem;
  padding: 0.75rem;
  .menu_item {
    padding: 0.75rem 1rem;
    cursor: pointer;
    border: none;
    border-radius: 0.25rem;
    background-color: black;
    color: white;
    &:hover {
      background-color: lighten(black, 10%);
    }
    &.active {
      background-color: lighten(black, 20%);
    }
  }
}
