.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  color: white;
}

.marquee__content {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 20s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
