.table_history {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: black;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.5);
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .close_btn {
      color: white;
    }
  }
  .body {
    background: lighten(black, 10%);
    display: relative;
    height: 100%;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    max-height: 100%;
    overflow-y: auto;
    .history_list {
      display: flex;
      flex-direction: column;
      .history_item {
        display: flex;
        align-items: center;
      }
    }
  }
  .history_detail {
    background-color: lighten(black, 20%);
    p {
      margin: 0rem;
    }
  }
  .time {
    margin-left: 0.5rem;
    font-size: 0.75rem;
    color: darken(white, 75%);
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #2d2d2d rgba(white, 0.025);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.5rem;
}

*::-webkit-scrollbar-track {
  background: rgba(white, 0.025);
}

*::-webkit-scrollbar-thumb {
  background-color: #2d2d2d;
  border-radius: 1rem;
}
