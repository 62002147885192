.chat {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: black;
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.5);
  .header {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .body {
    background: lighten(black, 10%);
    display: relative;
    overflow-y: auto;
    overflow-x: hidden;
    .chat_list {
      .chat_item {
        display: flex;
        align-items: center;
        // margin-bottom: 0.5rem;
        .chat_detail {
          position: relative;
          font-size: 0.8rem;
          margin-left: 0.5rem;
          padding: 0.5rem 0.75rem;
          border-radius: 0.5rem;
          background-color: lighten(black, 20%);
          p {
            margin: 0rem;
          }
        }
        .chat_detail_bottom_left:after {
          content: ' ';
          position: absolute;
          width: 0;
          height: 0;
          right: auto;
          left: -22px;
          top: auto;
          bottom: 0;
          border: 14px solid;
          border-color: transparent lighten(black, 20%) transparent transparent;
        }
        .chat_detail_bottom_right:after {
          content: ' ';
          position: absolute;
          width: 0;
          height: 0;
          right: -22px;
          left: auto;
          top: auto;
          bottom: 0;
          border: 14px solid;
          border-color: transparent transparent transparent lighten(black, 20%);
        }
      }
    }
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #2d2d2d rgba(white, 0.025);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.5rem;
}

*::-webkit-scrollbar-track {
  background: rgba(white, 0.025);
}

*::-webkit-scrollbar-thumb {
  background-color: #2d2d2d;
  border-radius: 1rem;
}
