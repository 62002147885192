.notification_list {
    padding: 0;
    margin: 0;
    list-style: none;
    max-height: 40vh;
    height: auto;
  }
  
  .notification_item {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
  
    &:last-child {
      border-bottom: none;
    }
  }

  .notification_text_bold {
    font-size: 14px;
    color: #333;
    font-weight: bold;
  }
  
  .notification_text {
    font-size: 14px;
    color: #333;
  }
  
  .notification_time {
    font-size: 12px;
    color: #999;
  }
  