.profile_information {
  display: block;
  position: relative;
  padding: 0.5rem 1.5rem;
  .row {
    display: flex;
    align-items: center;
    .col {
      display: block;
    }
  }
}

.player_avatar {
  margin-right: 1rem;
}

.player_name {
  display: block;
  width: 100%;
  text-transform: uppercase;
  text-align: right;
  max-width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.player_email {
  display: block;
  width: 100%;
  text-align: right;
  color: #687281;
  // font-size: 0.75rem;
  // max-width: 10rem;
  // white-space: nowrap;
  // overflow: hidden;
  max-width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e3e6ec;
}

.feather {
  height: 1rem;
  width: 1rem;
  vertical-align: top;
  height: 1em;
  width: 1em;
  margin-right: 0.5rem;
  color: #a2acba;
}

// Override MUI
.profile_information {
  li {
    font-family: 'Outfit', sans-serif;
  }
}
