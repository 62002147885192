.HandRank {
  position: absolute;
  top: -60px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 142px;
  height: 64px;
  zoom: 0.5;
  max-height: 64px;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  z-index: 2;
  padding-left: 20px;
  padding-right: 20px;
  background-color: rgba(0, 0, 0, .75);
  width: 100%;
  border-radius: 0.25rem .25rem 0 0;
  .RankText {
    display: flex;
    flex-direction: row;
    position: relative;
    font-size: 2rem;
  }
  .RankProgressBar {
    display: inline-block;
    position: relative;
    background: black;
    width: 285px;
    height: 36px;
    right: 15px;
    border-radius: 14px;
  }
  .progressBar {
    width: 372px;
    height: 28px;
    border-radius: 10px;
    margin-top: 4px;
    margin-left: 4px;
  }

  .progressBar.low {
    background-image: linear-gradient(to right, red, orange);
  }

  .progressBar.med {
    background-image: linear-gradient(to right, orange, yellow);
  }

  .progressBar.high {
    background-image: linear-gradient(to right, yellow, limegreen);
  }
}
