.player-actions {
  position: absolute;
  bottom: 0rem;
  width: 100%;
  display: flex;
  justify-content: center;
  .poker-actions {
    width: 35rem;
    max-width: 35rem;
    background-color: black;
    position: relative;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-grow: 1;
  }
}
