@import '../../mixins.scss';

.poker-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  float: left;
  &.poker-card {
    width: 3rem;
    height: 5rem;
    border-radius: 0.25rem;
    background-color: white;
    padding: 0.5rem 0.25rem;
  }
  .card-number-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    .card-number {
      width: 100%;
      font-size: 2rem;
      line-height: 1;
      background-repeat: no-repeat;
      background-position: bottom;
      background-size: 1.75rem;
      font-weight: bold;
      cursor: default;
      text-transform: uppercase;
      text-align: center;
    }
  }
  .background-card {
    height: 1.5rem;
    width: 1.5rem;
    display: none;
    background-repeat: no-repeat;
    align-self: center;
    margin-bottom: 0.25rem;
  }
  .card-number,
  .background-card {
    &.d {
      color: #1659a8;
      background-image: url(../../../public/cards/diamond.svg);
    }
    &.h {
      color: #c42021;
      background-image: url(../../../public/cards/heart.svg);
    }
    &.c {
      color: #378912;
      background-image: url(../../../public/cards/club.svg);
    }
    &.s {
      color: #000000;
      background-image: url(../../../public/cards/spade.svg);
    }
  }
}

.card-g1 .poker-card-container,
.card-g2 .poker-card-container {
  margin: 0 0 0.5rem 0.5rem;
}
