@import 'colors', 'variables', 'fonts';

@mixin authLogoPadding() {
  padding-top: 8rem;
}

@mixin OverFlowHideScrollbar() {
  overflow: scroll;
  // /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@mixin removeOuterBorder {
  border-style: hidden;
}

@mixin stickyHeader {
  position: sticky;
  z-index: 100;
  top: 0;
}
