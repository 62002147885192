.tournamant-panel {
  position: fixed;
  color: white;
  right: -550px;
  width: 550px;
  background-color: #000000;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  z-index: 999;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.tournamant-panel.open {
  right: 0 !important;
}

.tournament-name {
  text-align: center;
  color: #e98c26;
  font-family: 'Outfit';
  margin-bottom: 10px;
}

.box {
  background-color: #20262c;
  border-radius: 10px;
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
  padding: 10px;
  margin: 0 6px;
}

.label-name {
  color: #e98c26;
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.row-table {
  margin-top: 10px;
  width: 500px;
  margin-left: 10px;
}

.long-box {
  width: 100%;
  background-color: #20262c;
  margin: 0 6px;
  border-radius: 10px;
  text-align: center;
  padding: 16px;
  display: flex;
  justify-content: center;
}

.medium-box {
  width: 65%;
  background-color: #20262c;
  margin: 0 6px;
  border-radius: 10px;
  text-align: center;
  padding: 16px;
  display: flex;
  justify-content: center;
}

.column {
  display: inline-block;
  width: 25%;
  height: 50px;
  border-radius: 5px;
  text-align: left;
}

.column-center {
  display: inline-block;
  width: 25%;
  height: 50px;
  border-radius: 5px;
  text-align: left;
}

hr {
  height: 1px;
  background-color: #4c4c4c;
  border: none;
  margin-left: 8px;
}

.column-button {
  display: inline-block;
  width: 25%;
  height: 50px;
  text-align: center;
  background-color: #5a6771;
  color: white;
  cursor: pointer;
  align-items: center;
  line-height: 3;
}

.column-button:hover {
  text-decoration: none;
  background-color: #e98c26;
}

.column-button:not(:last-child) {
  border-right: 1px solid white;
}

.column-button:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.column-button:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.active-tab {
  background-color: #e98c26;
}

.inactive-tab {
  background-color: #5a6771;
}

.div-table {
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-left: 0rem;
  overflow-y: auto;
  font-family: 'Outfit';
}

.tournament-table {
  border-collapse: collapse;
  width: 100%;
}

.th {
  color: #e98c26;
}

.th,
.td {
  padding: 10px;
  width: 25%;
  text-align: center;
}

.thead-table {
  position: sticky;
  top: 0;
  z-index: 1;
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  background-color: #000000;
}

.prizes-th {
  color: #e98c26;
  padding: 10px;
  width: 50%;
}

.label-center {
  text-align: center;
}

.w-20 {
  width: 20%;
}

.w-80 {
  width: 80%;
}

.label-left {
  text-align: left;
}

.label-right {
  text-align: right;
}

.table-border-bottom {
  border-bottom: 1px solid #5a6771;
}

.table-layout {
  background-color: #024849;
  border: 3px solid #000;
  border-radius: 40px;
  height: 40px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 13px #29f11c;
  color: #29f11c;
}

.ai-outline-user {
  font-size: 1.3rem;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-right: 5px;
  margin-bottom: -4px;
}

.first-place {
  color: goldenrod;
  margin-right: 0px;
}

.seconds-place {
  color: silver;
  margin-right: 0px;
}

.third-place {
  color: #cd7f32;
  margin-right: 0px;
}

.current-place {
  font-weight: bold;
  color: #e98c26;
}
