@import '../../assets/scss/mixins';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main_wrapper {
  position: relative;
  background: radial-gradient(135.01% 135.01% at 50% 50%, #012425 3.65%, #000000 100%);
  min-height: 100vh;
  color: white;
}

.inner_wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hijack_logo {
  padding-top: 8rem;
  font-size: 2.5rem;
  font-weight: 500;
  letter-spacing: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  color: #e98c26;

  @include logoFont();
}
