@import '../../mixins.scss';

.bet-container {
  position: absolute;
  background-color: #c09687;
  border-radius: 1.5rem;
  padding: 0rem 0.75rem 0rem 0.75rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  column-gap: 15%;
  @include zoom;

  .text {
    font-size: 1rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #191311;
    font-weight: 500;
    @include noselect;
  }

  .chip-icon-wrapper {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
  }
}

.chip-img {
  display: block;
  height: 2.25rem;
}
