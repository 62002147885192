@use '@material/elevation/mdc-elevation';

$card-width: 2.7vw;

.card-container {
  // See Seats.scss for the positioning of cards
  // specific to player position
  position: absolute;
  display: flex;
  z-index: 2;
}

.card {
  background-color: #633520;
  border: 0.25rem solid #7e594a;
  width: 3rem;
  height: 5rem;
  border-radius: 0.25rem;
  background-image: url('../../../public/hj_logo.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  z-index: 2;
}
