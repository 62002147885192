@import '../../assets/scss/mixins';

.main_wrapper {
  background: $background-radial;
  min-height: 100vh;
  position: relative;
  color: white;
  padding-bottom: 5rem;
  @include primeFont();

  .lobby {
    // margin-left: 2rem;
    // margin-right: 2rem;
    .city_container {
      position: absolute;
      color: $white;
      top: 4%;
      left: 10.5%;
      font-size: 1rem;
      letter-spacing: 0.5px;
      font-weight: 700;
    }

    .buyChips_container {
      position: absolute;
      color: $white;
      top: 95%;
      left: 92%;
      display: flex;
      font-size: 0.875rem;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.87);
      width: 109px;
      height: 34px;
      background: #36393c;
      align-items: center;
      justify-content: space-between;
      padding: 0 0.6rem;
      border-radius: 30px;

      .circular {
        background: #20262d;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        text-align: center;
        padding-top: 1px;
      }

      .text {
        line-height: 18px;
      }
    }

    .buyChips_container:hover {
      cursor: pointer;
    }

    .logo_container {
      position: absolute;
      top: 0;
      transform: translate(-4.2rem, -3.5rem);
    }

    .navbar {
      // background-color: orangered;
      // display: grid;
      // grid-template-rows: 6.2rem;
      // grid-template-columns: 1fr 1fr;
      // grid-gap: 1rem;
      // width: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;

      &__item__left {
        // justify-self: start;
        // margin: 1.65rem 1.94rem;
        // background-color: $hijack-orange;
        padding-right: 0rem;
        // margin-bottom: auto;
        // max-height: 2rem;
      }

      &__item__right {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-items: center;
        position: relative;

        &__cashier {
          background-color: $gray-button-color;
          align-self: stretch;
          height: 4.75rem;
          width: 7rem;
          .text {
            text-align: center;
            margin-top: 1.6rem;
          }
        }

        &__balance {
          width: 5rem;
          text-align: center;
          height: 4.75rem;
          line-height: 1.25rem;
          font-size: 0.875rem;
          margin-top: 1rem;
        }

        &__avatar {
          width: 7rem;
          .container {
            width: 3.2rem;
            height: 3.2rem;
            margin: -1rem auto 0;
          }
        }
      }
    }

    .navigation_tab {
      display: grid;
      grid-template-columns: 1fr 50.1% 1fr;
      align-items: end;
      grid-gap: 4rem;
      margin-top: -4.3rem;

      .navigation_tab_group {
        margin-left: 2rem;
        display: flex;
        flex-direction: row;
        gap: 1rem;

        .active {
          background-color: $hijack-orange;
        }
        button {
          background-color: $gray-button-color;
          margin-top: 3rem;
          width: 9rem;
          height: 3.6rem;
          padding: 1rem;
          border-radius: 0.5rem;
          border: none;
          // font-size: 1.5rem;
          letter-spacing: 0.5px;
          color: $white;
          font-weight: 400;
          line-height: 1.5rem;
        }

        button:hover {
          cursor: pointer;
          background-color: darken($hijack-orange, 10);
          transition: 0.2s;
        }

        .tab_button_inner_container {
          display: flex;
          justify-content: space-between;
          // align-items: center;
          gap: 0.2rem;
          position: absolute;

          &__cash_container {
            padding: 0 1.5rem;
          }

          &__text {
            // margin-top: -2px;
            font-size: 1rem;
          }

          &__tournament {
            // color: green !important;
            transform: translateY(-11px) !important;
          }

          &__sitnGo {
            // color: red;
            // transform: translateY(2px);
          }
        }

        .tab_button_inner_container > div {
          // color: red;
          transform: translateY(-0.7rem);
        }
      }
      .empty_rectangle {
        background-color: $dark-gray;
        // margin-top: -2rem;
        height: 143px;
        visibility: hidden;
      }
    }

    main {
      // background-color: red;
      // height: 39rem;
      // max-height: 39rem;

      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 5.63rem 1fr;

      .table_view_options {
        // background-color: burlywood;
        display: flex;
        justify-content: left;
        gap: 0.5rem;
        align-items: center;

        .table_view_options_order_by {
          font-weight: 400;
          letter-spacing: 0.5px;
          display: flex;
          // background-color: yellow;
          gap: 1rem;
          align-items: center;
          margin-left: 2rem;

          &__item {
            background-color: $gray-button-color;
            width: 14.5rem;
            height: 1.875rem;
            padding: 0 0.75rem;
            padding-top: 2px;
            display: flex;
            justify-content: space-between;

            .arrow_down {
              width: 12px;
              height: 12px;
              transform: translateY(60%) rotate(180deg);
              clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
              background-color: $gray-triangle-pointer;
            }

            .arrow_up {
              width: 12px;
              height: 12px;
              transform: translateY(60%) rotate(180deg);
              clip-path: polygon(50% 100%, 0 0, 100% 0);
              background-color: $gray-triangle-pointer;
            }
          }
        }

        .table_view_options_filters {
          display: flex;
          // background-color: yellow;
          gap: 2rem;
          align-items: center;
          margin-left: 1rem;
          letter-spacing: 0.5px;
          font-weight: 400;

          .icon {
            margin-top: 0;
          }

          &__item {
            display: flex;
            align-items: center;

            input {
              // appearance: none;
              // -webkit-appearance: none;
              // border-radius: 50%;
              // background: $hijack-orange;
              // border: 1px solid $hijack-orange;
              // width: .7rem;
              // height: .7rem;
              // /* The outline will be the outer circle */
              // outline: 1px solid #999;
              appearance: none;
              background-color: inherit;
              margin: 0;
              font: inherit;
              color: $hijack-orange-dark;
              width: 0.7rem;
              height: 0.7rem;
              border: 0.1em solid $hijack-orange;
              border-radius: 50%;
              // transform: translateY(.2rem);
              outline: 2px solid $hijack-orange;
            }

            input:checked {
              background: $hijack-orange;
            }

            .options {
              display: flex;
              flex-direction: row;
              gap: 0.8rem;
              transform: translateX(-1rem);
              cursor: pointer;
            }

            .active_option {
              color: $hijack-orange-dark;
            }

            .radio_input2 {
              color: $hijack-orange;
            }
          }
        }
      }

      .main_content {
        display: grid;
        grid-template-columns: 64.5% 1fr;
        grid-template-rows: 1fr;

        &__left {
          // background-color: yellowgreen;
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
          margin-left: 2rem;

          &__1 {
            // background-color: palevioletred;
            width: 65%;
            height: 30.5rem;

            @include OverFlowHideScrollbar();
          }

          &__2 {
            // background-color: brown;
            width: 33.5%;
            height: 30.5rem;

            @include OverFlowHideScrollbar();
          }

          table {
            width: 100%;
            border-collapse: collapse;

            @include removeOuterBorder();
          }

          td,
          th {
            border: 0.5px solid $table-border-gray;
          }

          th {
            background-color: $table-header-black;
            padding: 0.5rem;
            text-align: left;
            font-weight: 400;

            @include stickyHeader();
          }

          tbody {
            tr {
              height: 3.5rem;
              font-weight: 700;
              background-color: $table-body-gray;

              td {
                padding-left: 0.5rem;

                .tables_play_container {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  padding-right: 1rem;
                  .play_button {
                    background-color: $white;
                    width: 2rem;
                    height: 2rem;
                    border-radius: 100%;
                    position: relative;

                    .triangle {
                      position: absolute;
                      background-color: $hijack-orange-dark;
                      width: 1rem;
                      height: 1rem;
                      top: 50%;
                      left: 55%;
                      transform: translate(-50%, -50%);
                      clip-path: polygon(100% 50%, 10% 0, 10% 100%);
                    }
                  }
                }

                .tables_play_container_center {
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  padding-right: 1rem;
                  .play_button {
                    background-color: $white;
                    width: 2rem;
                    height: 2rem;
                    border-radius: 100%;
                    position: relative;

                    .triangle {
                      position: absolute;
                      background-color: $hijack-orange-dark;
                      width: 1rem;
                      height: 1rem;
                      top: 50%;
                      left: 55%;
                      transform: translate(-50%, -50%);
                      clip-path: polygon(100% 50%, 10% 0, 10% 100%);
                    }
                  }
                  .play_button_in_progress {
                    background-color: #00b900;
                    width: 2rem;
                    height: 2rem;
                    border-radius: 100%;
                    position: relative;

                    .triangle {
                      position: absolute;
                      background-color: white;
                      width: 1rem;
                      height: 1rem;
                      top: 50%;
                      left: 55%;
                      transform: translate(-50%, -50%);
                      clip-path: polygon(100% 50%, 10% 0, 10% 100%);
                    }
                  }
                }
              }
            }

            tr:hover {
              background-color: $hijack-orange;
              // transition: .1s;
            }
          }
        }

        &__tournament {
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
          margin-left: 0rem;
          overflow-y: auto;
          height: 100%;
          max-height: 700px;
        }

        &__right {
          background-color: $poker-table-preview-container;
          position: relative;
          margin-left: 5rem;
          margin-right: 2rem;
          height: 30.5rem;

          .table_details {
            padding-top: 1.5rem;
            text-align: center;
            &__1 {
              font-weight: 700;
              letter-spacing: 0.5px;
              color: #e98c26;
            }
            &__2 {
              font-weight: 400;
              font-size: 0.875rem;
              letter-spacing: 0.5px;
              color: $poker-table-preview-details;
              padding-top: 5px;
            }
            &__3 {
              font-weight: 400;
              font-size: 0.875rem;
              letter-spacing: 0.5px;
              color: #ffffff;
              padding-top: 20px;
              text-align: left;
              padding-left: 0px;
              padding-right: 15px;
              line-height: 2;
            }
          }

          .table_visual {
            background-color: $poker-table-preview-color;
            position: absolute;
            top: 47%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 22.3rem;
            height: 9.5rem;

            border-radius: 16rem;
            div {
              // background-color: green;
              width: 1.1875rem;
              height: 1.1875rem;
              // position: relative;
              font-size: 0.875rem;
            }

            .top_left__text {
              position: absolute;
              top: -18%;
              left: 20%;
              transform: translate(-50%, -50%);
              text-align: center;
              width: fit-content;
            }

            .top_left__circle {
              position: absolute;
              top: -8px;
              left: 33%;
              transform: translate(-50%, -50%);
              clip-path: circle(50% at 50% 50%);
            }

            .top_right__text {
              position: absolute;
              top: -18%;
              left: 80%;
              transform: translate(-50%, -50%);
              width: fit-content;
            }
            .top_right__circle {
              position: absolute;
              top: 7%;
              left: 93%;
              transform: translate(-50%, -50%);
              clip-path: circle(50% at 50% 50%);
            }

            .top_mid__text {
              position: absolute;
              top: -18%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: fit-content;
            }

            .top_mid__circle {
              position: absolute;
              top: -8px;
              left: 64%;
              transform: translate(-50%, -50%);
              clip-path: circle(50% at 50% 50%);
            }

            .left_middle__text {
              position: absolute;
              top: 49%;
              left: -13%;
              transform: translate(-50%, -50%);
              width: fit-content;
            }

            .left_middle__circle {
              position: absolute;
              top: 56%;
              left: -8px;
              transform: translate(-50%, -50%);
              clip-path: circle(50% at 50% 50%);
            }

            .right_middle__text {
              position: absolute;
              top: 49%;
              left: 113%;
              transform: translate(-50%, -50%);
              width: fit-content;
            }

            .right_middle__circle {
              position: absolute;
              top: 56%;
              left: 102%;
              transform: translate(-50%, -50%);
              clip-path: circle(50% at 50% 50%);
            }

            .left_bottom__circle {
              position: absolute;
              top: 105%;
              left: 21%;
              transform: translate(-50%, -50%);
              clip-path: circle(50% at 50% 50%);
            }

            .left_bottom__text {
              position: absolute;
              top: 113%;
              left: 20%;
              transform: translate(-50%, -50%);
              width: fit-content;
            }

            .mid_bottom__text {
              position: absolute;
              top: 118%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: fit-content;
            }
            .mid_bottom__circle {
              background-color: $hijack-orange;
              position: absolute;
              top: 106%;
              left: 53%;
              transform: translate(-50%, -50%);
              clip-path: circle(50% at 50% 50%);
            }

            .right_bottom__text {
              position: absolute;
              top: 113%;
              left: 80%;
              transform: translate(-50%, -50%);
              width: fit-content;
            }
            .right_bottom__circle {
              position: absolute;
              top: 104%;
              left: 84%;
              transform: translate(-50%, -50%);
              clip-path: circle(50% at 50% 50%);
            }

            .left_upper_left__circle {
              position: absolute;
              top: 7%;
              left: 7%;
              transform: translate(-50%, -50%);
              clip-path: circle(50% at 50% 50%);
            }
          }

          .table_visual__actions {
            display: flex;
            position: absolute;
            top: 88%;
            left: 50%;
            gap: 1.6rem;
            transform: translate(-50%, -50%);

            button {
              width: 9rem;
              height: 3.5rem;
              border-radius: 0.5rem;
              border: none;
              font-size: 1rem;
              letter-spacing: 0.5px;
              color: $white;
            }

            .join_table {
              background-color: $hijack-orange;
            }
            .join_table:hover {
              cursor: pointer;
              background-color: darken($hijack-orange, 10);
              transition: 0.2s;
            }
            .observe {
              background-color: $gray-button-color;
            }
          }
        }
      }
    }

    footer {
      // background-color: orchid;
      margin: 2rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      justify-content: space-between;

      div {
        height: 143px;
      }

      .footer_empty_left {
        background-color: $dark-gray;
        width: 907px;
        transform: translateY(-1rem);
        visibility: hidden;
      }

      .footer_empty_right {
        background-color: $dark-gray;
        width: 658px;
        margin-right: 8rem;
        transform: translate(-1rem, -1rem);
        visibility: hidden;
      }
    }
  }
}

/* Site Navigation */
.site_header {
  color: black;
  width: 100%;
  .logo_link {
    display: flex;
    align-items: center;
  }
  .site_navigation {
    display: flex;
    justify-content: flex-end;
    .nav_item {
      display: flex;
      align-items: center;
      a {
        padding: 1.25rem 2rem;
        // display: block;
        display: flex;
        align-items: center;
        color: white;
        font-size: 16px;
        text-decoration: none;
        position: relative;
        font-weight: 100;
        height: 100%;
        font-family: 'Outfit', sans-serif !important;
        &:hover {
          font-family: 'Outfit', sans-serif !important;
          // font-size: 0.75rem;
          // font-weight: 400;
          background-color: rgba(25, 118, 210, 0.04);
        }
      }
    }
  }
}

.timer_display {
  font-family: fantasy;
  font-size: 50px;
}

.div_table {
  display: table;
  width: 100%;
  border-spacing: 5px; /* cellspacing:poor IE support for  this */
}
.div_table_row {
  display: table-row;
  width: 100%;
  clear: both;
}
.div_table_col {
  float: left;
  display: table-column;
  width: 21%;
  padding-left: 15px;
}
.div_table_col_value {
  float: left;
  display: table-column;
  width: 29%;
  text-align: right;
  padding-right: 2px;
}

.header_wrapper {
  background-color: $midnight-blue;
}
