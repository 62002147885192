@import '../../mixins';
$top-cards: -10vh;
$seat-container-width: 6vw;
$bottom-cards: $top-cards;
$half-seat-container-width: calc($seat-container-width / 2);

.seat-container {
  position: absolute;
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &.focused-player {
    .circle {
      width: $seat-container-width + 1;
      height: $seat-container-width + 1;
    }
  }
  @include disabled();
}

.seat-container {
  &:nth-child(1) {
    bottom: -7rem;
    .bet-container {
      bottom: 8.5rem;
    }
  }
  &:nth-child(2) {
    bottom: -7rem;
    left: 8rem;
    .bet-container {
      bottom: 8.5rem;
    }
  }
  &:nth-child(3) {
    bottom: 1rem;
    left: calc(0rem - 6rem);
    .bet-container {
      bottom: 5rem;
      left: 8.5rem;
    }
  }
  &:nth-child(4) {
    bottom: 18rem;
    left: calc(0rem - 3.5rem);
    .bet-container {
      bottom: -3rem;
      left: 7.5rem;
    }
  }
  &:nth-child(5) {
    bottom: 23rem;
    left: 14rem;
    .bet-container {
      bottom: -6.5rem;
    }
  }
  &:nth-child(6) {
    bottom: 23rem;
    left: 33rem;
    .bet-container {
      bottom: -6.5rem;
    }
  }
  &:nth-child(7) {
    bottom: 18rem;
    left: calc(54rem - 3.5rem);
    .bet-container {
      bottom: -3rem;
      left: -5.5rem;
    }
  }
  &:nth-child(8) {
    bottom: 1rem;
    left: calc(54rem - 1rem);
    .bet-container {
      bottom: 4.5rem;
      left: -7.5rem;
    }
  }
  &:nth-child(9) {
    bottom: -7rem;
    left: 39rem;
    .bet-container {
      bottom: 8.5rem;
    }
  }
}

.seat-container {
  &:nth-child(-n + 5) {
    // 1 up to 5
    // Cards will be on the right of the player avatar
    .card-container {
      margin-left: 8rem;
      .player-card:nth-child(2),
      .card:nth-child(2) {
        transform: rotate(8deg);
        margin-left: -1rem;
      }
    }
  }
  &:nth-child(n + 6):nth-child(-n + 9) {
    // 6 up to 9
    // Cards will be on the right of the player avatar
    .card-container {
      margin-left: -4rem;
      left: 0rem;
      .player-card:nth-child(1),
      .card:nth-child(1) {
        transform: rotate(350deg);
        margin-right: -0.5rem;
      }
    }
  }
}
