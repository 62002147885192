@import '../../assets/scss/mixins';

.hijack_logo {
  font-size: 2.5rem;
  font-weight: 500;
  letter-spacing: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  color: $hijack-orange;

  @include logoFont();
}
