// @import url('https://fonts.googleapis.com/css2?family=Outfit&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;400;500&display=swap');

@font-face {
  font-family: Aldivaro-Rough;
  src: url('./Aldivaro_Rough.woff');
  font-weight: bold;
}

@mixin primeFont() {
  font-family: 'Outfit', sans-serif;
}

@mixin logoFont() {
  font-family: Aldivaro-Rough;
}
