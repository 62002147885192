@import '../../mixins';
.pot-container {
  position: absolute;
  background-color: #090f13;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  @include zoom;
  .text {
    text-align: center;
    font-size: 1rem;
    color: #dfe0e0;
    @include noselect;
  }
}

.sidepot-container {
  position: relative;
  background-color: #090f13;
  text-align: center;
  white-space: nowrap;
  @include zoom;
  .text {
    text-align: center;
    font-size: 1rem;
    color: #dfe0e0;
    @include noselect;
  }
}
