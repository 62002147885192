@import '../../mixins';

.player-info {
  position: absolute;
  bottom: 0rem;
  margin-bottom: -3.75rem;
  display: flex;
  flex-direction: column;
  .player-name {
    white-space: nowrap;
    text-align: center;
    padding-bottom: 0.25rem;
  }
  .player-cash {
    display: flex;
    justify-content: center;
    @include zoom;
    @include noselect;
    .text {
      border-radius: 3rem;
      text-align: center;
      background-color: #161b1f;
      font-size: 1rem;
      color: #e1e1e2;
      padding: 0.25rem 1.5rem;
    }
  }
}

.action-cell {
  position: absolute;
  bottom: 0%;
  // left: -70%;
  bottom: 6.25rem;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0.25rem 1rem;
  @include zoom;
  .text {
    cursor: default;
    color: #e1e1e2;
    font-size: 0.75rem;
    @include noselect;
  }
}

.players-name {
  position: absolute;
  bottom: -35%;
  text-align: center;
  margin-left: -45%;
}

.dealer-btn-container {
  position: absolute;
  left: -70px;
  bottom: 65px;
  border-radius: 100vw;
  width: 30px;
  height: 30px;
  background-color: #8c3041;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.charge-btn-container {
  position: absolute;
  left: -70px;
  bottom: 25px;
  border-radius: 100vw;
  width: 30px;
  height: 30px;
  background-color: green;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.charge-hidden {
  opacity: 0;
  -moz-animation: fadeInAndOut 4s; /* Firefox < 16 */
  -ms-animation: fadeInAndOut 4s; /* Internet Explorer */
  -o-animation: fadeInAndOut 4s; /* Opera < 12.1 */
  animation: fadeInAndOut 4s;
}

@keyframes fadeInAndOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.bgglow {
  -webkit-animation: bgglow 1000ms;
  -moz-animation: bgglow 1000ms;
  -o-animation: bgglow 1000ms;
  animation: bgglow 1000ms;
  border-radius: 3rem;
}
@-webkit-keyframes bgglow {
  0% {
    -webkit-box-shadow: 0 0 10px #fff8cd;
  }
  50% {
    -webkit-box-shadow: 0 0 20px #ffefa0;
  }
  100% {
    -webkit-box-shadow: 0 0 10px #ffe05d;
  }
}

@-moz-keyframes bgglow {
  0% {
    -moz-box-shadow: 0 0 10px #fff8cd;
  }
  50% {
    -moz-box-shadow: 0 0 20px #ffefa0;
  }
  100% {
    -moz-box-shadow: 0 0 10px #ffe05d;
  }
}

@-o-keyframes bgglow {
  0% {
    box-shadow: 0 0 10px #fff8cd;
  }
  50% {
    box-shadow: 0 0 20px #ffefa0;
  }
  100% {
    box-shadow: 0 0 10px #ffe05d;
  }
}

@keyframes bgglow {
  0% {
    box-shadow: 0 0 10px #fff8cd;
  }
  50% {
    box-shadow: 0 0 20px #ffefa0;
  }
  100% {
    box-shadow: 0 0 10px #ffe05d;
  }
}

.bgglowgreen {
  -webkit-animation: bgglowgreen 1000ms;
  -moz-animation: bgglowgreen 1000ms;
  -o-animation: bgglowgreen 1000ms;
  animation: bgglowgreen 1000ms;
  border-radius: 3rem;
}
@-webkit-keyframes bgglowgreen {
  0% {
    -webkit-box-shadow: 0 0 10px #07da63;
  }
  50% {
    -webkit-box-shadow: 0 0 20px #06c258;
  }
  100% {
    -webkit-box-shadow: 0 0 10px #06a94d;
  }
}

@-moz-keyframes bgglowgreen {
  0% {
    -moz-box-shadow: 0 0 10px #07da63;
  }
  50% {
    -moz-box-shadow: 0 0 20px #06c258;
  }
  100% {
    -moz-box-shadow: 0 0 10px #06a94d;
  }
}

@-o-keyframes bgglowgreen {
  0% {
    box-shadow: 0 0 10px #07da63;
  }
  50% {
    box-shadow: 0 0 20px #06c258;
  }
  100% {
    box-shadow: 0 0 10px #06a94d;
  }
}

@keyframes bgglowgreen {
  0% {
    box-shadow: 0 0 10px #07da63;
  }
  50% {
    box-shadow: 0 0 20px #06c258;
  }
  100% {
    box-shadow: 0 0 10px #06a94d;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

.fadeout-avatar {
  opacity: 0.3;
  animation: fadeOut 1s ease;
}

.win-animation {
  animation: wiggle 2s linear 3;
}

@keyframes wiggle {
  0%,
  7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%,
  100% {
    transform: rotateZ(0);
  }
}
