@import '../../mixins';
$btns-container-height: 7.5vh;
$btns-container-width: 30vw;
$btns-bg-color: #643e32;
$btns-font-size: 0.8vw;
$actions-btns-width: 80%;
$balance-width: 100% - $actions-btns-width;
$btns-column-gap: 2px;
.actions-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  display: block;
  font-family: 'Outfit', sans-serif;
}

.player-actions {
  .player-cards-container {
    position: absolute;
    height: 10rem;
    bottom: 0.5rem;
    display: flex;

    .poker-card-container {
      background-color: #fcfff7;
      position: relative;
      width: 6rem;
      height: 100%;
      border-radius: 0.2vw;
      transition: transform 0.2s;
      @include zoom(1.2);
    }

    .card-number-container {
      width: 2rem;
      height: 4.5rem;
      .card-number {
        width: 1.75rem;
        font-size: 2rem !important;
      }
    }
    .background-card {
      width: 5rem !important;
      height: 6rem !important;
      display: block;
    }
  }
}

.hidden {
  display: none;
}

/* Site Navigation */
.site_header {
  color: black;
  width: 100%;
  .logo_link {
    display: flex;
    align-items: center;
  }
  .site_navigation {
    display: flex;
    justify-content: flex-end;
    .nav_item {
      display: flex;
      align-items: center;
      a,
      button {
        padding: 1rem 1.5rem;
        margin-left: 0.25rem;
        // display: block;
        display: flex;
        align-items: center;
        color: white;
        font-size: 1rem;
        text-decoration: none;
        position: relative;
        font-weight: 100;
        height: 100%;
        cursor: pointer;
        &:hover {
          background-color: rgba(25, 118, 210, 0.04);
        }
      }
    }
    &.dark-bg {
      .nav_item {
        a,
        button {
          background-color: rgba(#20262d, 0.4);
        }
        &.balance {
          a,
          button {
            background-color: rgba(#20262d, 0.2);
          }
        }
      }
    }
  }
}
